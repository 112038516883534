import HeaderBar from "../../components/HeaderBar";

function Revision() {
  return (
    <div className="rev">
      <HeaderBar title="Lost Wills? Not With Willful" lw="65%" />
      <div className="jb-ac column-small" style={{ marginTop: 20 }}>
        <div className="revcb" style={{padding: 16, background: '#daede8', borderRadius: 12}}>
          <div className="revst">Stay in Control</div>
          {st1}
        </div>
        <div className="reviw">
          <img alt="" src="/img/newFam.png" className="hw100" />
        </div>
        <div className="revcb" style={{padding: 16, background: '#daede8', borderRadius: 12}}>
          <div className="revst">Auto-Submission</div>
          {st2}
        </div>
      </div>
    </div>
  );
}

export default Revision;

const st1 =
  "Willful keeps track of each update to your will, protecting your most recent wishes. Users may also elect to keep a copy for themselves as a PDF.";
const st2 =
  "By using publicly available death records, Willful knows when and where to send your will for probate. Users may opt into this feature at any time.";
