const StepCircle = ({ children, top, left }) => {
  return(
    <div style={{position: 'absolute', left: left, width: 74, height: 74, borderRadius: '50%', top: top, background: '#64C6AC'}}>
      <div style={{width: '80%', height: '80%', borderRadius: '50%', border: '2px solid white', margin: '10%', color:'white', fontSize: 20, fontWeight: 600}} className='jc-ac'>
        {children}
      </div>
    </div>
  )
}

export default StepCircle
