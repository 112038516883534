const HeaderBar = ({ title, lw }) => {
  let lineWidth = lw || '38%'
  let m = title === 'Need to Revisit Things? No Problem.' ? 14 : 0
  return(
    <>
      <div className='header-bar' style={{paddingLeft: m, paddingRight: m}}>{title}</div>
      <div className='jc-ac'>
        <div style={{width: lineWidth}} className='header-underline'/>
      </div>
    </>
  )
}

export default HeaderBar
