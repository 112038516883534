import MailchimpSubscribe from "react-mailchimp-subscribe";
import { useWindowSizes } from 'react-use-window-sizes'

const url =
  "https://mywillful.us14.list-manage.com/subscribe/post?u=7cf0e5939b4f6b8f76acffef3&amp;id=9fc9d3146f";

const Contact = () => {
  const { width } = useWindowSizes()
  const title = width < 801 ? 'Questions?' : 'Questions? Comments? Feedback?'
  return (
    <section className="cont jc-ac" id="contact">
      <div className="contt">{title}</div>
      <div className="fs22 fs18s">Let's connect</div>
      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => (
          <ContactForm
            status={status}
            message={message}
            onValidated={formData => subscribe(formData)}
          />
        )}
      />
    </section>
  );
};

export default Contact;

const ContactForm = ({ status, message, onValidated }) => {
  let name, email, subject, msg;
  const submitMessage = () =>
    subject &&
    email &&
    name &&
    msg &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value,
      NAME: name.value,
      SUBJ: subject.value,
      MSG: msg.value
    });

  return (
    <>
      {status === "sending" && (
        <div style={{ color: "blue", textAlign: "center", fontSize: 18 }}>
          sending...
        </div>
      )}
      {status === "error" && (
        <div
          style={{ color: "red", textAlign: "center", fontSize: 18 }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === "success" && (
        <div
          style={{ color: "green", textAlign: "center", fontSize: 18 }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      <input
        className="contact-input"
        placeholder="Name"
        name="name"
        ref={node => (name = node)}
      />
      <input
        className="contact-input"
        placeholder="Email"
        name="email"
        ref={node => (email = node)}
      />
      <input
        className="contact-input"
        placeholder="Subject"
        name="subject"
        ref={node => (subject = node)}
      />
      <textarea
        name="msg"
        className="contact-textarea"
        placeholder={`What's on your mind?`}
        ref={node => (msg = node)}
      />
      <div className="contact-submit jc-ac" onClick={() => submitMessage()}>
        Send
      </div>
    </>
  );
};
