import HeaderBar from "../../components/HeaderBar";

//larger planet background photo

function Planet() {
  return (
    <section className="plan" id="planet" style={{}}>
      <HeaderBar title="Protecting the Planet" lw="35%" />
      <div className="jc-ac pt10">
        <div className="jb-ac planmw">
          <div className="w28 tac w42s">
            <img alt="" src="/img/circleLogo.png" className="w60" />
            <div className="planit">Willful</div>
          </div>
          <img alt="" src="/img/plus.png" className="planp" />
          <div className="w28 tac w42s">
            <img alt="" src="/img/otp1.png" className="w60" />
            <div className="planit">One Tree Planted</div>
          </div>
        </div>
      </div>
      <div className="jc-ac pt40">
        <div className="plandb">
          {plandb1}
          <div style={{ marginTop: 16 }}>{plandb2}</div>
        </div>
      </div>
    </section>
  );
}

export default Planet;

const plandb1 =
  "In addition to improving estate planning accessibility, Willful seeks to help the environment by maintaining a negative carbon footprint.";
const plandb2 =
  "For every E-Will created on Willful, we will pledge a dollar in your name to plant a new tree in Malawi through our partnership with One Tree Planted.";
