import HeaderBar from "../../components/HeaderBar";
import { useWindowSizes } from 'react-use-window-sizes'

function Mission() {
  const { width } = useWindowSizes()
  const mt2 = width < 801 ? 30 : 0
  return (
    <section className="rev" id="mission" style={{marginBottom: width < 801 ? 60 : ''}}>
      <HeaderBar title="Our Mission" lw="30%" />
      <div style={{ marginTop: 30 }} className="jb-ac column-small">
        <div className="missst" style={{padding: 16, background: '#daede8', borderRadius: 12}}>
          <div className="misstt" style={{lineHeight: 1}}>Protect More Families</div>
          {c1}
        </div>
        <div className="missiw">
          <img alt="" src="/img/hands.png" className="hw100" style={{objectFit: 'cover'}} />
        </div>
      </div>
      <div style={{ marginTop: mt2 }} className="jb-ac column-small">
        <div className="missiw hide-small">
          <img alt="" src="/img/laptop.png" className="hw100" />
        </div>
        <div className="missst" style={{padding: 16, background: '#daede8', borderRadius: 12}}>
          <div className="misstt" style={{lineHeight: 1}}>Educate and Empower</div>
          {c2}
        </div>
      </div>
    </section>
  );
}

export default Mission;

const c1 =
  "Only 33% of Americans have a will today. Willful aims to increase the accessibility of estate planning tools and documents by offering a quicker, less costly alternative.";
const c2 =
  "We plan to help individuals learn more about the estate planning process.  Through education, Americans can take charge of their legacies without falling victim to the monotonous status quo.";
