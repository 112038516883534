// import { useState } from 'react'
import { useWindowSizes } from "react-use-window-sizes";
import StepCircle from "../../components/StepCircle";

const Timeline = () => {
  const { width } = useWindowSizes();
  const s2 = width < 801 ? 650 : 698;
  const s3 = width < 801 ? 1234 : 1256;

  return (
    <section className="time" id="timeline" style={{ overflow: "hidden" }}>
      <div className="timew">
        <div className="js">
          <div className="timel" />
          <div className="timelw">
            <SectionTitle type={1} />
            <SectionTitle type={2} />
            <SectionTitle type={3} />
          </div>
        </div>
        <div>
          <div className="je-ac">
            <img
              alt=""
              src="/img/contract.png"
              className="timei timei3 hide-small"
            />
          </div>
          <div style={{ marginTop: -70, marginLeft: -150 }} className="img1">
            <img alt="" src="/img/family.png" className="timei" />
          </div>
          <div style={{ marginTop: 170, marginLeft: -150 }} className="img2">
            <img alt="" src="/img/phone.png" className="timei" />
          </div>
          <div style={{ marginTop: -70 }} className="je-ac">
            <img
              alt=""
              src="/img/dudeSmiling.png"
              className="timei timei3 hide-small"
            />
          </div>
          <div style={{ marginTop: 180, width: "100%" }} className="jc-ac img3">
            <img alt="" src="/img/girlCat.png" className="timei timei36" />
          </div>
        </div>
        <StepCircle left={-30} top={64} children={1} />
        <StepCircle left={-30} top={s2} children={2} />
        <StepCircle left={-30} top={s3} children={3} />
      </div>
    </section>
  );
};

export default Timeline;

const SectionTitle = ({ type }) => {
  const d = titles[type];
  const { width } = useWindowSizes();
  const t = width < 801 ? d.top2 : d.top;
  return (
    <div style={{ marginTop: t }}>
      <div style={{ fontSize: 40, fontWeight: 600 }}>{d.t}</div>
      <div style={{ width: d.lw }} className="timetl" />
      <div
        className="timet"
        style={{
          padding: 16,
          background: "#daede8",
          borderRadius: 12,
          marginLeft: -14,
        }}
      >
        <strong>{d.s} </strong>
        {d.c}
      </div>
    </div>
  );
};

const titles = {
  1: {
    t: "Create",
    s: "Create your will in about 15 minutes",
    c: "by completing our asset and directive questionnaire, designed by industry-leading estate planners",
    top: 66,
    top2: 66,
    lw: 122,
  },
  2: {
    t: "Notarize",
    s: "Choose a time to have it notarized",
    c: "by scheduling a virtual appointment with one of our notary publics through our booking engine",
    top: 390,
    top2: 316,
    lw: 150,
  },
  3: {
    t: "Store",
    s: "Willful stores your will securely",
    c: "in Google Cloud using a multi-layer security protocol. You can access your will at any time, and Willful can submit a copy to probate on your behalf",
    top: 310,
    top2: 310,
    lw: 96,
  },
};
