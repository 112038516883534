import {
  FaTwitterSquare,
  FaFacebookSquare,
  FaInstagramSquare,
} from "react-icons/fa";
import { BiMenu } from "react-icons/bi";
import Menu from "../../components/Menu";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { Link } from "react-scroll";

const url =
  "https://mywillful.us14.list-manage.com/subscribe/post?u=7cf0e5939b4f6b8f76acffef3&amp;id=9fc9d3146f";

function LandingMain({ setMenu, menu }) {
  return (
    <div>
      <div className="header jb-ac">
        <img alt="" src="/img/logo.png" className="main-logo" />
        <div className="fdc-ae main-links">
          <div className="hiw">
            <a href="https://twitter.com/mywillful" className="hia">
              <FaTwitterSquare className="hi" />
            </a>
            <a href="https://www.facebook.com/mywillful/" className="hia">
              <FaFacebookSquare className="hi" />
            </a>
            <a href="https://www.instagram.com/mywillful/" className="hia">
              <FaInstagramSquare className="hi" />
            </a>
          </div>
          <div className="je-ac">
            <HeaderLink t="How it Works" h="#hiw" />
            <HeaderLink t="Our Mission" h="#mission" />
            <HeaderLink t="Social Mission" h="#planet" />
            <HeaderLink t="Contact Us" h="#contact" />
          </div>
        </div>
        <div className="small-links">
          <Link
            activeClass="active"
            to="hiw"
            spy={true}
            smooth={true}
            offset={30}
            duration={500}
          >
            <div
              style={{
                border: "2px solid rgb(100, 198, 172)",
                marginRight: 14,
                borderRadius: 3,
                paddingTop: 2,
                paddingBottom: 2,
              }}
            >
              <div
                style={{
                  fontWeight: 500,
                  marginLeft: 10,
                  marginRight: 10,
                  textDecoration: "none",
                  color: "#454c4d",
                }}
              >
                How it Works
              </div>
            </div>
          </Link>
          <div onClick={() => setMenu(!menu)}>
            <BiMenu style={{ fontSize: 38 }} />
          </div>
        </div>
      </div>
      <Menu menu={menu} setMenu={setMenu} />
      <div className="main">
        <div className="mainh">Secure Your Family's Future</div>
        <div className="mainc">{c1}</div>
        <MailchimpSubscribe
          url={url}
          render={({ subscribe, status, message }) => (
            <ContactForm
              status={status}
              message={message}
              onValidated={(formData) => subscribe(formData)}
            />
          )}
        />
      </div>
    </div>
  );
}

export default LandingMain;

const HeaderLink = ({ t, h }) => {
  return (
    <div className="hlw">
      <a href={h} className="hla">
        {t}
      </a>
    </div>
  );
};

const ContactForm = ({ status, message, onValidated }) => {
  const windowWidth = window.innerWidth;
  const signupStyle =
    windowWidth > 600
      ? { justifyContent: "left", marginTop: 20 }
      : { justifyContent: "center" };

  let email;
  const submitMessage = () =>
    email &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value,
    });

  return (
    <div className="main-form">
      <div className="df pb30" style={signupStyle}>
        <input
          style={{ maxWidth: "50%", color: "green" }}
          placeholder="Email Me When We Launch"
          className="maini"
          name="email"
          ref={(node) => (email = node)}
        />
        <div className="jc-ac mainfb" onClick={() => submitMessage()}>
          SEND
        </div>
      </div>
      {status === "sending" && (
        <div className="mcfa" style={{ color: "blue" }}>
          sending...
        </div>
      )}
      {status === "error" && (
        <div
          className="mcfa"
          style={{ color: "red" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === "success" && (
        <div
          className="mcfa"
          style={{ color: "green" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
    </div>
  );
};

const c1 =
  "We're building an app where you can create, notarize, and store your will and trust docs in one secure place. Without all the legal fees.";
