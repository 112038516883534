import { useLockBodyScroll } from '../hooks/disableScroll'
import { useWindowSizes } from 'react-use-window-sizes'
import { Link } from 'react-scroll'

const Menu = ({ menu, setMenu, r1 }) => {
  const { width } = useWindowSizes()

  if ( width < 801) {
    return(
      <>
        <div style={{ width: '100%', height: menu ? 150 : 0, background: '#fefefe', zIndex: 1001, transition: 'all 200ms linear', display: 'flex', flexDirection: 'column', alignItems: 'center', overflow: 'hidden', boxShadow: 'inset 0px 0px 6px rgba(0,0,0,.22)'}}>
          <Link activeClass="active" to="hiw" spy={true} smooth={true} offset={-120} duration={500} style={{width:'100%'}}>
            <div onClick={() => setMenu(false)} style={{textDecoration: 'none', fontSize: 18, color: '454c4d', paddingTop: 6, paddingBottom: 6, borderBottom: '1px solid rgba(0,0,0,.14)', width: '100%', marginTop: 6, textAlign: 'center'}}>
              How it Works
            </div>
          </Link>
          <Link activeClass="active" to="mission" spy={true} smooth={true} offset={-120} duration={500} style={{width:'100%'}}>
            <div onClick={() => setMenu(false)} style={{textDecoration: 'none', fontSize: 18, color: '454c4d', paddingTop: 6, paddingBottom: 6, borderBottom: '1px solid rgba(0,0,0,.14)', width: '100%', textAlign: 'center'}}>
              Our Mission
            </div>
          </Link>
          <Link activeClass="active" to="planet" spy={true} smooth={true} offset={-120} duration={500} style={{width:'100%'}}>
            <div onClick={() => setMenu(false)} style={{textDecoration: 'none', fontSize: 18, color: '454c4d', paddingTop: 6, paddingBottom: 6, borderBottom: '1px solid rgba(0,0,0,.14)', width: '100%', textAlign: 'center'}}>
              Social Mission
            </div>
          </Link>
          <Link activeClass="active" to="contact" spy={true} smooth={true} offset={-120} duration={500} style={{width:'100%'}}>
            <div onClick={() => setMenu(false)} style={{textDecoration: 'none', fontSize: 18, color: '454c4d', paddingTop: 6, paddingBottom: 6, width: '100%', textAlign: 'center'}}>
              Contact Us
            </div>
          </Link>
        </div>
      </>
    )
  } else {
    return null;
  }
}

export default Menu
