import { useState } from "react";
import Main from "./Main";
import HiW from "./HowItWorks";
import Revision from "./Revision";
import Mission from "./Mission";
import Planet from "./Planet";
import Contact from "./Contact";
import Timeline from "./Timeline";
import Footer from "./Footer";
import { useWindowSizes } from "react-use-window-sizes";

function LandingPage() {
  const [menu, setMenu] = useState(false);
  const { width } = useWindowSizes();

  return (
    <div style={{ maxWidth: width, overflowX: "hidden" }}>
      <Main setMenu={setMenu} menu={menu} />
      <HiW />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div style={{ maxWidth: 1300 }}>
          <Timeline />
          <Revision />
          <Mission />
        </div>
      </div>
      <Planet />
      <Contact />
      <Footer />
    </div>
  );
}

export default LandingPage;
