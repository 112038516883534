import HeaderBar from "../../components/HeaderBar";

function HiW({ refOne }) {
  return (
    <section className="pb100" id="hiw" ref={refOne}>
      <HeaderBar title="How Does Willful Work?" />
      <div className="jc-ac">
        <div className="jb-ac w100 column-small" style={{ maxWidth: 1000 }}>
          <div style={{ maxWidth: 340 }}>
            <div
              style={{ padding: 16, background: "#daede8", borderRadius: 12 }}
            >
              <div className="tac fs22 fs18s">
                <strong>It's your life</strong> {c1}
              </div>
            </div>
            <div className="jc-ac hide-small">
              <div className="hiwb jc-ac">
                <a href="#timeline" className="hiwba">
                  Learn More
                </a>
              </div>
            </div>
          </div>
          <div className="hiwiw jc-ac">
            <img
              alt=""
              src="/img/gif.gif"
              style={{ width: "100%", borderRadius: "50%" }}
            />
          </div>
          <div className="jc-ac hide-big">
            <div className="hiwb jc-ac" style={{ marginTop: 30 }}>
              <a href="#timeline" className="hiwba">
                Learn More
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HiW;

const c1 =
  "and your assets. Click through our 3 step process to learn how to better secure your family.";
const c2 = "How would you like to distribute your assets?";
const c3 = "Leaving everything to my spouse";
const c4 = "Split between my spouse & children";
